import { createAsyncThunk } from '@reduxjs/toolkit'
import { token as getToken } from '../users/selectors'
import { RootState } from '../../root-types'
import { selectAppointmentType, selectCurrentBuildingId } from '../../app/selectors'
import { normalize, schema } from 'normalizr'
import { setManyOrders } from '../../orders/orders-slice'
import {
  clearRequestFailure,
  clearRequestSuccess,
  getAllAppointmentStatusesFailure,
  getAllAppointmentStatusesSuccess,
  getAppointmentsFailure,
  setSearchAttributes
} from './appointment-slice'
import {
  AppointmentEntity,
  getAppointmentsFulfilled,
  setManyAppointments
} from '../../appointments/appointments-slice'
import axios from 'axios' // Make sure to import axios or your preferred HTTP client
import { AppointmentURLPayload, getAppointmentURL, updateAppointmentOnList } from './utils'
// @ts-ignore - react-notifications doesn't have type definition yet
import { NotificationManager } from 'react-notifications'
import {
  addAppointmentToTable,
  addTempDeletedAppointment,
  moveAppointmentInTable,
  removeTempDeletedAppointment,
  setAppointments
} from '../TableAppointments/table-appointments-slice'
import { createGetCarrierById, getAllCarriersFulfilled } from '../../carriers/carriers-slice'
import { CarrierSchema } from '../carriers/actions'
import { setSelectedOrders } from '../orders/order-slice'
import { createGetDoorById, selectAllDoors } from '../../doors/doors-slice'
import { createGetDriverEntityById } from '../../drivers/drivers-slice'
import Order from '../../types/Order'
import OrderItem from '../../types/OrderItem'
import moment from 'moment'

import appointmentSelectors, {
  getAppointmentSearchAttributes,
  getSearchAttributesCount
} from './selectors'
import { selectAllAreas } from '../../areas/areas-slice'
import { selectAllBuildings } from '../../buildings/buildings-slice'
import { selectAppointmentsForDoors } from '../TableAppointments/selectors'
import Appointment from '../../types/Appointment'
import { setBuildingId, setSiteId } from '../../app/actions'
import { getAppointmentsForDoors } from '../TableAppointments/actions'
import { fetchAllCarrierRequests, getNumberOfCarrierRequests } from '../carrierRequests/actions'
import { createGetAppointmentById, createGetAppointmentsById } from '../../appointments/selectors'
import Raven from 'raven-js'

const baseUrl = '/appointments'
export const AppointmentSchema = new schema.Entity('appointments')

export const fetchAppointments = createAsyncThunk(
  'appointments/getAppointments',
  async (payload: AppointmentURLPayload, { dispatch, getState }) => {
    try {
      const state = getState() as RootState
      const token = getToken(state)
      const appointmentTypes = selectAppointmentType(state)

      const url = getAppointmentURL(payload, appointmentTypes)

      const {
        data: { appointments: data, pages }
      } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      // Normalize data if needed
      const normalizedData = normalize(data, [AppointmentSchema])
      const appointmentEntities = normalizedData.entities?.appointments

      // Dispatch action to update state
      if (appointmentEntities) {
        dispatch(getAppointmentsFulfilled(Object.values(appointmentEntities)))
      }

      const orders = {}
      // Process orders if needed

      // Dispatch action to update state with orders
      dispatch(setManyOrders(Object.values(orders)))

      // Dispatch success action
      return { data, pages }
    } catch (error) {
      Raven.captureException(error)
      // Dispatch failure action
      dispatch(getAppointmentsFailure(error))
      throw error
    }
  }
)

export const fetchAppointment = createAsyncThunk(
  'appointments/getAppointment',
  async (id, { getState }) => {
    try {
      const state = getState() as RootState
      const token = getToken(state)

      // Make the API call
      const response = await axios.get(`${baseUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      // Dispatch success action
      return response.data
    } catch (error) {
      Raven.captureException(error)
      // Dispatch failure action
      throw error
    }
  }
)

export const fetchAppointmentWithOrders = createAsyncThunk(
  'appointments/getAppointmentWithOrders',
  async (payload: any, { getState }) => {
    try {
      const { id } = payload
      const state = getState() as RootState
      const token = getToken(state)

      // Make the API call
      const response = await axios.get(`${baseUrl}/${id}/orders`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      // Dispatch success action
      return response.data
    } catch (error) {
      Raven.captureException(error)
      // Dispatch failure action
      throw error
    }
  }
)
export const createAppointment = createAsyncThunk(
  'appointments/createAppointment',
  async (payload: any, { dispatch, getState }) => {
    try {
      const sendingData = { ...payload }
      delete sendingData.items
      delete sendingData.appointmentStatus
      delete sendingData.building
      delete sendingData.door
      delete sendingData.site
      delete sendingData.orders

      const state = getState() as RootState
      const token = getToken(state)
      // Make the API call to create an appointment
      const response = await axios.post(baseUrl, sendingData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      const data = response.data

      // Process carrier data if needed
      if (data.carrier) {
        const normalizedData = normalize([data.carrier], [CarrierSchema])
        // @ts-ignore
        dispatch(getAllCarriersFulfilled(Object.values(normalizedData?.entities?.carriers)))
      }

      // Dispatch success action
      dispatch(addAppointmentToTable(data))
      return data
    } catch (error: any) {
      Raven.captureException(error)

      // Handle different error cases
      if (!error.response) {
        NotificationManager.error('Appointments could not be created.')
        throw error
      }

      const { status, data } = error.response
      switch (status) {
        case 409:
          NotificationManager.error(data.error)
          break
        case 400:
          NotificationManager.error(`Appointment request failed: ${data}`)
          break
        case 422:
          NotificationManager.error('Appointments missing required fields.')
          break
        case 412:
          NotificationManager.error(data ? data.error : 'One or more orders are already scheduled.')
          break
        default:
          NotificationManager.error('Appointments could not be created.')
          break
      }

      // Dispatch failure action
      throw error
    }
  }
)

export const updateAppointment = createAsyncThunk(
  'appointments/updateAppointment',
  async (payload: any, { dispatch, getState }) => {
    if (payload.id == null) {
      return
    }

    try {
      const sendingData = { ...payload }
      delete sendingData.items
      delete sendingData.appointmentStatus
      delete sendingData.building
      delete sendingData.door
      delete sendingData.site
      delete sendingData.orders

      const preserveUndefinedPayload = JSON.stringify(sendingData, (k, v) =>
        v === undefined ? null : v
      )

      const state = getState() as RootState
      const token = getToken(state)

      // Make the API call to update the appointment
      const response = await axios.put(`${baseUrl}/${payload.id}`, preserveUndefinedPayload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      const data = response.data

      // Process carrier data if needed
      if (data.carrier) {
        const normalizedData = normalize([data.carrier], [CarrierSchema])
        // @ts-ignore
        dispatch(getAllCarriersFulfilled(Object.values(normalizedData?.entities?.carriers)))
      }

      return data
    } catch (error: any) {
      Raven.captureException(error)

      // Handle different error cases
      if (!error.response) {
        NotificationManager.error('Appointments could not be modified.')
        throw error
      }

      const { status, data } = error.response
      switch (status) {
        case 409:
          NotificationManager.error(data.error)
          break
        case 400:
          NotificationManager.error(`Appointment request failed: ${data}`)
          break
        case 422:
          NotificationManager.error('Appointments missing required fields.')
          break
        case 412:
          NotificationManager.error(data.error)
          break
        default:
          NotificationManager.error(data.message || 'Appointments could not be modified.')
          break
      }

      // Dispatch failure action
      throw error
    }
  }
)

export const removeOrderFromAppointment = createAsyncThunk(
  'appointments/removeOrderFromAppointment',
  async (
    {
      appointmentId,
      orderId
    }: {
      appointmentId: number
      orderId: number
    },
    { getState }
  ) => {
    try {
      const state = getState() as RootState
      const token = getToken(state)
      // Make the API call to remove the order from the appointment
      await axios.delete(`${baseUrl}/${appointmentId}/orders/${orderId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    } catch (error) {
      Raven.captureException(error)

      throw error
    }
  }
)

export const deleteAppointment = createAsyncThunk(
  'appointments/deleteAppointment',
  async (id: number, { getState, dispatch }) => {
    const state = getState() as RootState
    const token = getToken(state)
    dispatch(addTempDeletedAppointment(id))

    try {
      await axios.delete(`${baseUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    } catch (error) {
      NotificationManager.error('Appointment could not be deleted.')
      Raven.captureException(error)
      throw error
    } finally {
      dispatch(removeTempDeletedAppointment(id))
    }

    return id
  }
)

export const getAllAppointmentStatuses = createAsyncThunk(
  'appointments/getAllAppointmentStatuses',
  async (_, { getState, dispatch }) => {
    try {
      const state = getState() as RootState
      const token = getToken(state)

      const response = await axios.get('/appointment_statuses', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      const data = response.data

      dispatch(getAllAppointmentStatusesSuccess(data))
    } catch (error) {
      Raven.captureException(error)

      dispatch(getAllAppointmentStatusesFailure(error))
      throw error
    }
  }
)

export const moveAppointment = createAsyncThunk(
  'appointments/moveAppointment',
  async (payload: any, { getState, dispatch }) => {
    const state = getState() as RootState
    const getAppointmentById = createGetAppointmentById(state)
    const oldAppointment = getAppointmentById(payload.id)

    try {
      dispatch(moveAppointmentInTable(payload))
      const token = getToken(state) // Dispatch the existing editAppointment action

      const response = await axios.put(`${baseUrl}/${payload.id}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      return response.data
    } catch (error: any) {
      Raven.captureException(error)

      if (error.response) {
        const { status } = error.response
        if (status === 400) {
          NotificationManager.error(
            "Appointment couldn't be created because it was overlapping another one."
          )
        } else if (status === 422) {
          NotificationManager.error('Appointment missing required fields.')
        } else {
          NotificationManager.error('Appointment could not be created.')
        }
      }

      dispatch(moveAppointmentInTable(oldAppointment))
      throw error
    }
  }
)

export const getAppointmentsForWarehouse = createAsyncThunk(
  'appointments/getAppointmentsForWarehouse',
  async (_, { getState, dispatch }) => {
    try {
      const state = getState() as RootState
      const buildingId = selectCurrentBuildingId(state)
      const token = getToken(state)
      const doors = selectAllDoors(state)
      const areas = selectAllAreas(state)

      const buildingArea = areas.find(a => a.buildingId === buildingId)
      const buildingDoors = doors.filter(d => d.areaId === buildingArea?.id)

      let attributes = ''

      buildingDoors.forEach((door, index) => {
        index > 0 ? (attributes += `&doorId=${door.id}`) : (attributes += `doorId=${door.id}`)
      })

      // Make the API call to get appointments with filtered attributes
      const { data } = await axios.get(`/appointments?${attributes}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      // Normalize and dispatch appointments data
      const normalizedData = normalize(data, [AppointmentSchema])
      // @ts-ignore
      dispatch(setManyAppointments(normalizedData.entities.appointments))

      // Dispatch success action
      dispatch(setAppointments(data))
    } catch (error) {
      Raven.captureException(error)

      // Dispatch failure action
      dispatch(getAppointmentsFailure(error))
      throw error
    }
  }
)
export const clearRequest = createAsyncThunk(
  'appointments/clearRequest',
  async (id: number, { getState, dispatch }) => {
    try {
      // Make the API call to clear the request
      const state = getState() as RootState
      const token = getToken(state)
      const response = await axios.post(`${baseUrl}/${id}/clear-request`, null, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      const data = response.data

      // Dispatch success action
      dispatch(clearRequestSuccess(data))

      // Dispatch carrier requests related actions
      dispatch(fetchAllCarrierRequests({}))
      dispatch(getNumberOfCarrierRequests())
    } catch (error) {
      Raven.captureException(error)

      // Dispatch failure action
      dispatch(clearRequestFailure(error))
      throw error
    }
  }
)

export type AppointmentWithSocketPayload = Appointment | Appointment[]

export const updateAppointmentWithSocketAppointment = createAsyncThunk(
  'appointments/updateWithSocketAppointment',
  async (payload: AppointmentWithSocketPayload, { dispatch, getState }) => {
    try {
      const data = Array.isArray(payload) ? payload : [payload]
      const normalizedData = normalize<Appointment[]>(data, [AppointmentSchema])

      dispatch(setManyAppointments(normalizedData.entities.appointments as unknown as AppointmentEntity[]))

      const state = getState() as RootState
      const warehouse = selectCurrentBuildingId(state)
      const doors = selectAllDoors(state)
      const areas = selectAllAreas(state)
      const buildings = selectAllBuildings(state)

      const doorMap = new Map(doors.map(d => [d.id, d]))
      const areaMap = new Map(areas.map(a => [a.id, a]))
      const buildingMap = new Map(buildings.map(b => [b.id, b]))

      let appointmentsForDoors = selectAppointmentsForDoors(state)
      let appointmentsSideBar = appointmentSelectors.getAppointments(state)

      for (const appointment of data) {
        const appointmentDoor = doorMap.get(appointment.doorId)
        const appointmentArea = appointmentDoor ? areaMap.get(appointmentDoor.areaId) : null
        const appointmentBuilding = appointmentArea ? buildingMap.get(appointmentArea.buildingId) : null

        const newAppointment = {
          ...appointment,
          carrierRequests: appointment?.carrierRequests?.map((c: any) => ({ ...c })) || [],
          orders: appointment?.orders?.map((o: any) => ({ ...o })) || [],
          inventoryIssues: appointment.inventoryIssues || {}
        } as Appointment

        if (appointmentBuilding && appointmentBuilding.id === warehouse) {
          appointmentsForDoors = updateAppointmentOnList(newAppointment, appointmentsForDoors, true)
        }

        appointmentsSideBar = updateAppointmentOnList(newAppointment, appointmentsSideBar, false)
      }

      dispatch(setAppointments(appointmentsForDoors))
    } catch (error) {
      Raven.captureException(error)
    }
  }
)

export const searchAppointments = createAsyncThunk(
  'searchAppointmentsThunk',
  async (payload: any, { dispatch, getState }) => {
    dispatch(setSearchAttributes(payload))

    const state = getState() as RootState
    const attributes = getAppointmentSearchAttributes(state)
    const attributesCount = getSearchAttributesCount(state)

    const data = {
      ...attributes,
      appointmentStatusId: attributes.appointmentsStatusSelect,
      customerId: attributes.customerSelect,
      shippingDate: attributes.shippingDateSelect
        ? attributes.shippingDateSelect.format('YYYY-MM-DD')
        : null,
      destinationId: attributes.destinationSelect,
      page: attributes.currentPage
    }

    let dateFrom = null
    let dateTo = null

    if (attributes.shippingDateSelect) {
      dateFrom = moment(attributes.shippingDateSelect).subtract(1, 'day').format('L')
      dateTo = moment(attributes.shippingDateSelect).add(2, 'day').format('L')
    }

    if (!attributesCount) {
      data.buildingId = selectCurrentBuildingId(state)
    }

    await dispatch(
      fetchAppointments({
        ...data,
        dateFrom,
        dateTo
      } as unknown as AppointmentURLPayload)
    )
  }
)

export const fetchAppointmentsNextPage = createAsyncThunk(
  'appointments/fetchNextPage',
  async (payload: void, { dispatch, getState }) => {
    const state = getState() as RootState
    const searchParams = getAppointmentSearchAttributes(state)
    dispatch(
      fetchAppointments({
        ...searchParams,
        page: searchParams.currentPage + 1
      } as unknown as AppointmentURLPayload)
    )
  }
)

export const getMissingDownstreamAppointments = createAsyncThunk(
  'appointments/getMissingDownstreamAppointments',
  async (
    {
      appointment
    }: {
      appointment: Appointment
    },
    { dispatch, getState }
  ) => {
    const downstreamConflicts = appointment.meta.appointmentIssues.hasConflictingInventory
    if (!downstreamConflicts) {
      return
    }
    const state = getState() as RootState
    const token = getToken(state)

    const conflictingAppointmentIds = Object.keys(downstreamConflicts).reduce(
      (flatAppointments: any, sku) => {
        return Array.from(new Set([...flatAppointments, ...Object.keys(downstreamConflicts[sku])]))
      },
      []
    )
    const cachedAppointments = createGetAppointmentsById(state)(conflictingAppointmentIds)
    const missingAppointmentIds = conflictingAppointmentIds.filter(
      // @ts-ignore
      (id: string) => !cachedAppointments[id]
    )

    if (missingAppointmentIds.length > 0) {
      const queryParams = missingAppointmentIds.map((id: number) => `id=${id}`).join('&')

      const { data } = await axios.get(`${baseUrl}?page=1&per_page=100&${queryParams}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      const normalizedData = normalize(data.appointments, [AppointmentSchema])
      dispatch(setManyAppointments(normalizedData.entities.appointments as unknown as AppointmentEntity[]))
    }
  }
)

export interface OpenEditAppointment {
  appointment: Appointment
  tab: number
}

export interface OpenEditAppointmentPayload {
  appointment: Appointment
  tab?: number
}

export const openEditAppointment = createAsyncThunk(
  'appointments/openEditAppointment',
  async (
    payload: OpenEditAppointmentPayload,
    { dispatch, getState }
  ): Promise<OpenEditAppointment> => {
    const { appointment, tab = 0 } = payload
    if (!appointment.id) {
      return {
        appointment,
        tab
      }
    }

    const state = getState() as RootState
    const token = getToken(state)
    const cachedAppointment = createGetAppointmentById(state)(appointment.id)

    if (cachedAppointment) {
      dispatch(setSelectedOrders(cachedAppointment.orders))
      return {
        appointment: cachedAppointment as unknown as Appointment,
        tab
      }
    }

    const { data } = await axios.get(`${baseUrl}/${appointment.id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    const normalizedData = normalize(data, [AppointmentSchema])
    dispatch(setManyAppointments(normalizedData.entities.appointments as unknown as AppointmentEntity[]))

    return {
      appointment: data.appointment,
      tab
    }
  }
)

export const createFromRequest = createAsyncThunk(
  'appointments/createFromRequest',
  async ({ request, apptData = {} }: any, { dispatch, getState }) => {
    if (!request) return

    try {
      const state = getState() as RootState
      const getDoorById = createGetDoorById(state)
      const getDriverById = createGetDriverEntityById(state)
      const getCarrierById = createGetCarrierById(state)
      const token = getToken(state)

      const requestOrders = request.carrierRequestOrders
      const doorMeta = getDoorById(request.doorId)
      const area = doorMeta?.area
      const building = doorMeta?.area?.building
      const site = doorMeta?.area?.building?.site
      const driverId = request.driverId
      const carrierId = request.carrierId

      let data = []
      if (requestOrders?.length) {
        const poNumbers = requestOrders.map((ro: { poNumber: any }) => ro.poNumber)
        const url = `/orders?otherRefs=${poNumbers.join('&otherRefs=')}`
        const resp = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        data = resp.data
      }

      let date = request.date && moment(request.date)
      if (date && request.timeStart && !apptData.date) {
        date = moment.tz(
          `${date.format('YYYY-MM-DD')} ${moment.utc(request.timeStart).format('HH:mm')}`,
          request.building?.timezone ?? 'UTC'
        )
      } else {
        date = apptData.date
      }

      const placement = {
        area: area || null,
        building: building || null,
        site: site || null,
        areaId: area?.id,
        buildingId: building ? building.id : null,
        siteId: site?.id
      }

      const carrier = getCarrierById(carrierId)
      const driver = getDriverById(driverId)
      const items = data.orders.reduce(
        (items: OrderItem[], order: Order) => [...items, ...order.items],
        []
      )

      const appointment = {
        ...(request.doorId ? placement : {}),
        recalculateDuration: true,
        carrierRequestId: request.id,
        date,
        time: date,
        items,
        duration: 60,
        carrier: carrier,
        carrierId: carrier?.id,
        driver: driver,
        driverId: driver?.id,
        contactPhone: request.phone,
        email: request.email,
        trailer: request.trailerLicense,
        tractor: request.tractorNumber,
        carrierRequests: [request],
        orderIds: data.orders.map((o: Order) => o.id),
        requestId: request.id,
        orders: data.orders,
        ...apptData
      }
      dispatch(openEditAppointment({ appointment }))
    } catch (error) {
      Raven.captureException(error)
    }
  }
)

export const showAppointment = createAsyncThunk(
  'appointments/showAppointment',
  async ({ appointment }: any, { dispatch }) => {
    const { siteId, buildingId } = appointment

    const date = moment(appointment.date)
    if (date.hour() < 6) {
      date.subtract(1, 'day')
    }
    const startDate = date.format('L')
    const startTime = date.startOf('day').format('HH:mm:ss')
    const endDate = date.add(1, 'days').add(6, 'hours').format('L')
    const endTime = date.format('HH:mm:ss')

    dispatch(setSiteId(siteId))
    dispatch(setBuildingId(buildingId))

    await dispatch(
      getAppointmentsForDoors({
        buildingId,
        startDate,
        endDate,
        startTime,
        endTime
      })
    )
  }
)
