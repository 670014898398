import { ThNoBorder } from '../TableSctructure.styles'
import { DoorEntity } from '../../../../doors/doors-slice'
import DoorTitle from './component/DoorTitle'
import React, { useContext } from 'react'
import TimeTableContext from '../../utils/TableContext'
import { EmptyTh } from './TimeTableTitle.styles'

export interface TableHeaderProps {
  doors: DoorEntity[]
}

const TimeTableTitle = ({ doors }: TableHeaderProps) => {
  const { cellWidth, zoomLevel } = useContext(TimeTableContext)

  return (
    <tr>
      <ThNoBorder>Time</ThNoBorder>
      <th style={{ width: 0 }} />
      {doors?.map((door: DoorEntity) => (
        <DoorTitle key={`${door.id}event`} door={door} width={cellWidth} zoomLevel={zoomLevel} />
      ))}
      {doors.length <= 7 && <EmptyTh>&nbsp;</EmptyTh>}
    </tr>
  )
}

export default React.memo(TimeTableTitle)
