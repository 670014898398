import React from 'react'
import moment from 'moment/moment'
import { Container } from '../../styled/Containers'
import { useSelector } from 'react-redux'
import InventoryIssueBadge from './components/InventoryIssueBadge'
import {
  selectAppointmentType,
  selectCurrentBuildingTimezone,
  selectEndShift,
  selectStartShift
} from '../../app/selectors'
import Appointment from '../../types/Appointment'
import { EventCardBottomControls } from './components/EventCardBottomControls'
import { getUpdateAppointmentIsLoading } from '../../modules/appointments/selectors'
import { convertToCamelCase } from '../../utils/common'
import { requestStatusesMap } from '../../utils/time'
import { EventCardContent } from './EventCardContent/EventCardContent'
import { selectAppointmentStatusText } from './utils/selectors'
import EventCardHeaderControls from './components/CardHeaderControls'

export interface EventProps {
  appointment: Appointment
  size: number
  width?: any
  className?: string
  isDragging?: boolean
  drag?: any
  areIssuesDisplayed?: boolean
  isContinueNextDayHandled?: boolean
  isSizeDurationAware?: boolean
  isRequestLate?: boolean
}

export interface EventCardProps extends EventProps {
  onMouseDown: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  isDraggingCardDuration: boolean
  newDuration: number
}

const EventCard = (props: EventCardProps) => {
  const {
    appointment,
    className,
    size = 100,
    isRequestLate = false,
    width,
    drag,
    isDragging,
    isDraggingCardDuration,
    newDuration,
    onMouseDown,
    areIssuesDisplayed = true,
    isContinueNextDayHandled = true,
    isSizeDurationAware = true
  } = props

  const appointmentTypes = useSelector(selectAppointmentType)
  const isLoading = useSelector(getUpdateAppointmentIsLoading) === appointment.id

  const startShift = useSelector(selectStartShift)
  const endShift = useSelector(selectEndShift)

  const appointmentStatusText = useSelector(selectAppointmentStatusText)(
    appointment.appointmentStatusId
  )

  const timezone = useSelector(selectCurrentBuildingTimezone)
  const initTime = moment(appointment.date).tz(timezone)

  const duration = appointment.duration
  const finalTime = initTime
    .clone()
    .add(isDraggingCardDuration ? newDuration : duration, 'minutes')
    .subtract(1, 'second')

  const startBeforeShift = initTime < startShift
  const continuePreviousDay = isContinueNextDayHandled && !isDragging && startBeforeShift

  const endsAfterShift = finalTime > endShift
  const continueNextDay = isContinueNextDayHandled && !isDragging && endsAfterShift
  const getHeight = () => {
    let height = isSizeDurationAware
      ? ((isDraggingCardDuration ? newDuration : duration) * size * 140) / 6000
      : size

    if (isDragging || !isSizeDurationAware) {
      return height
    }

    const isNextDay = isContinueNextDayHandled && (endsAfterShift || startBeforeShift)
    if (isNextDay) {
      const diffDur = endsAfterShift
        ? endShift.diff(initTime, 'minutes')
        : finalTime.diff(startShift, 'minutes')
      return (diffDur * size * 140) / 6000
    }

    if (initTime.day() !== finalTime.day()) {
      height += Math.ceil(finalTime.diff(initTime, 'days', true)) * 30 // DaySeparator height
    }

    return height
  }
  const height = getHeight()

  const isCheckedOut = appointmentStatusText === 'Checked Out'
  const status = appointment.inProgress
    ? 'inProgress'
    : convertToCamelCase(
      isRequestLate && !isCheckedOut ? requestStatusesMap.carrierLate : appointmentStatusText
    )

  const isDisabled =
    (appointmentTypes === 1 && appointment.isOutbound) ||
    (appointmentTypes === 2 && !appointment.isOutbound) ||
    isLoading

  const containerClassName = continueNextDay ? 'event-wrapper continue-next-day' : 'event-wrapper'
  return (
    <div ref={drag} className={className}>
      <Container className={className} isDisabled={isDisabled}>
        {size > 25 && <InventoryIssueBadge appointment={appointment} disabled={isDisabled} />}
        <Container height={height} width={width} status={status} className={containerClassName}>
          <EventCardHeaderControls initTime={initTime} continuePreviousDay={continuePreviousDay} />

          <EventCardContent
            height={height}
            appointment={appointment}
            disabled={isDisabled}
            areIssuesDisplayed={areIssuesDisplayed}
            size={size}
            isRequestLate={isRequestLate}
          />

          <EventCardBottomControls
            onMouseDown={onMouseDown}
            finalTime={finalTime}
            continueNextDay={continueNextDay}
          />
        </Container>
      </Container>
    </div>
  )
}

export default React.memo(EventCard)
