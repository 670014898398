import Raven from 'raven-js'
const version = '1.3.0'
const envs = {
  local: {
    ENV: 'local',
    API_BASE: 'http://localhost:8080',
    API_KEY: '003026bbc133714df1834b8638bb496e-8f4b3d9a-e931-478d-a994-28a725159ab9',
    SENTRY_URL: '',
    VERSION: version
  },
  dev: {
    ENV: 'development',
    API_BASE: 'https://api.smartdock.dev.cleverbuild.biz',
    API_KEY: '003026bbc133714df1834b8638bb496e-8f4b3d9a-e931-478d-a994-28a725159ab9',
    SENTRY_URL: 'https://9aafb3bd5b0a4df5b459513a25c7edd7@sentry.cleverbuild.biz/34',
    VERSION: version
  },
  staging: {
    ENV: 'staging',
    API_BASE: 'https://api.smartdock.staging.cleverbuild.biz',
    API_KEY: '003026bbc133714df1834b8638bb496e-8f4b3d9a-e931-478d-a994-28a725159ab9',
    SENTRY_URL: 'https://9aafb3bd5b0a4df5b459513a25c7edd7@sentry.cleverbuild.biz/34',
    VERSION: version
  },
  prod: {
    ENV: 'production',
    API_BASE: 'https://api.smartdock.prod.cleverbuild.biz',
    API_KEY: '003026bbc133714df1834b8638bb496e-8f4b3d9a-e931-478d-a994-28a725159ab9',
    SENTRY_URL: 'https://9aafb3bd5b0a4df5b459513a25c7edd7@sentry.cleverbuild.biz/34',
    VERSION: version
  }
}

// Expected hosts:
// local  :             local.cleverbuild.biz
// dev    :   <name>-dev-<random>.example.com
// staging: <name>-stage-<random>.example.com
// prod   :                       example.com

const localHostnames = ['local.cleverbuild.biz', 'localhost', '127.0.0.1']
const developmentHostnames = [
  'https://smartdock.dev.cleverbuild.biz',
  'smartdock.dev.cleverbuild.biz',
  'www.smartdock.dev.cleverbuild.biz',
]
const stagingHostnames = [
  'https://smartdock.staging.cleverbuild.biz',
  'smartdock.staging.cleverbuild.biz',
  'www.smartdock.staging.cleverbuild.biz',
]
const productionHostnames = [
  'https://smartdock.prod.cleverbuild.biz',
  'smartdock.prod.cleverbuild.biz',
  'www.smartdock.prod.cleverbuild.biz',
]

const getConfig = () => {
  const { hostname } = window.location

  if (localHostnames.indexOf(hostname) > -1) {
    return envs.local
  } else if (developmentHostnames.indexOf(hostname) > -1) {
    return envs.dev
  } else if (stagingHostnames.indexOf(hostname) > -1) {
    return envs.staging
  } else if (productionHostnames.indexOf(hostname) > -1) {
    return envs.prod
  } else {
    console.log('No matching hostname found. Using local configuration for API Endpoints.')
    return envs.local
  }
}

const config = getConfig()
if (config.SENTRY_URL) {
  Raven.config(config.SENTRY_URL).install()
  Raven.setTagsContext({ environment: config.ENV })
}
export default getConfig()
