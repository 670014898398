import { getIsRequestLate, requestStatusesMap } from '../../../../utils/time'
import { DropEventProps } from '../../utils/types'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TimeTableContext from '../../utils/TableContext'
import Appointment from '../../../../types/Appointment'
import { convertToCamelCase } from '../../../../utils/common'
import DraggableTableEventCard from '../../../Event/DraggableTableEventCard'
import { selectAppointmentStatusText } from '../../../Event/utils/selectors'
import { createGetIsDoorAvailable } from '../../../../door-schedule/door-schedule-slice'
// @ts-ignore types not available
import { NotificationManager } from 'react-notifications'
import moment from 'moment'
import { moveAppointment } from '../../../../modules/appointments/actions'
import { selectIsAppointmentTempDeleted } from '../../../../modules/TableAppointments/selectors'

export interface AppointmentCardProps {
  appointment: Appointment
  first?: boolean
}

const TableAppointmentCard = (props: AppointmentCardProps) => {
  const { appointment, first } = props
  const dispatch = useDispatch()
  const isDoorAvailable = useSelector(createGetIsDoorAvailable)
  const appointmentStatus = useSelector(selectAppointmentStatusText)(
    appointment.appointmentStatusId
  )
  const isAppointmentTempDeleted = useSelector(
    selectIsAppointmentTempDeleted(appointment.id)
  )

  const { cellWidth, zoomLevel } = useContext(TimeTableContext)

  const isRequestLate = useMemo(() => {
    if (!appointmentStatus || !appointment) {
      return false
    }

    return getIsRequestLate(appointment, appointmentStatus)
  }, [appointment, appointmentStatus])

  const onDropEventOnTable = useCallback(({ props, door, hour }: DropEventProps) => {
    const { appointment } = props

    const appointmentEndAt = moment(appointment.date)
      .add(appointment.duration, 'minutes')
      .subtract(1, 'seconds')

    const doorAvailable = isDoorAvailable({
      doorId: door?.id,
      hour: appointmentEndAt
    })
    if (!doorAvailable) {
      NotificationManager.error('Door is not available at this time')
      return
    }
    const editingAppointment = {
      ...appointment,
      doorId: door?.id,
      date: hour,
      rwConnect: true
    }

    dispatch(moveAppointment(editingAppointment))
  }, [])

  const status = appointment.inProgress
    ? 'inProgress'
    : (convertToCamelCase(
        isRequestLate ? requestStatusesMap.carrierLate : appointmentStatus
      ) as string)

  return (
    <DraggableTableEventCard
      unavaliableAppointment={isAppointmentTempDeleted}
      first={first}
      status={status}
      isRequestLate={isRequestLate}
      size={zoomLevel}
      appointment={appointment}
      onDropOnTable={onDropEventOnTable}
      topPosition={0}
      width={cellWidth}
    />
  )
}

export default TableAppointmentCard
